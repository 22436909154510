import { Toast } from 'vant'
import { mapState } from 'vuex'
import OverlaySuccessNotice from '@/components/common/overlay-success-notice/index.vue'

/*
 * @Author: yanjing.feng 
 * @Date: 2020-05-12 14:56:59 
 * @Last Modified by: yanjing.feng
 * @Last Modified time: 2020-05-15 11:31:26
 */
const SECURITY_SEARCH_BASE = 'https://wsu.t3315.com/NewWebSrvCheckAddr/FWResult.aspx' //  调用的url
const FETCH_IP_URL = "http://pv.sohu.com/cityjson"   //  获取ip地址的url
const QryType = "APP" // 可选值WEB
const MD5 = "eFSlUcSaMHbL0ixdf3JW2w=="  //  防伪码md5
const IpAddr = "118.178.59.24" // 防伪码查询的参数

export default {
  name: 'security-search',
  components: {
    OverlaySuccessNotice,
  },
  data() {
    return {
      code: '', //  防伪码
      logoUrl: require('./../../../assets/images/security/logo@2x.png'),
      lifeUrl: require('./../../../assets/images/security/beauty-life@2x.png'),
      result: null,  //查询防伪码的结果
      loading: false,
      isTrueCode: false,
    }
  },
  computed: {
    ...mapState({
      backgroundImage: state => state.permission.permission.login.backgroundImage
    }),
    error() { //  请求是否错误：真码不错误；没有请求时是普通状态
      const { isTrueCode, code, result } = this
      return !isTrueCode && code && result
    }
  },
  watch: {
    code: function (newVal, oldVal) {  //  如果code被清空，那么重置搜索结果
      if (newVal != oldVal && !newVal) {
        this.onClear()
      }
    }
  },
  methods: {
    getIsTrueCode(result) {
      // （00 真码首次查询，91 真码重复查询，90 假码，95 数码过期，98系统繁忙）
      const { BackCode } = result
      return String(BackCode) === '00' || String(BackCode) === '91' || String(BackCode) === '95'
    },
    async onSubmit(e) {
      e.preventDefault()
      const { code } = this
      if (!code || !code.trim()) {
        Toast('防伪码不能为空~')
        this.code = ''
        this.$refs.codeInput.focus()
        return
      }
      this.loading = true
      const ip =  window.returnCitySN && window.returnCitySN["cip"]
      if (!ip) {
        Toast('系统无法获取您的IP地址，请刷新后重试或者关闭广告拦截器后重试~')
        return
      }
      this.$jsonp(SECURITY_SEARCH_BASE, {
        // eslint-disable-next-line no-undef
        QryType, MD5, IpAddr: ip, FwCode: code, callbackQuery: 'jsoncallback', callbackName: 'jsoncallback'
      }).then(json => {
        this.result = json || {}
        this.isTrueCode = this.getIsTrueCode(json)
        if (this.isTrueCode) {
          this.$nextTick(() => {
            this.$refs.successDialog.show()
          })
        }
        this.loading = false
      }).catch(err => {
        Toast.fail(`查询防伪异常~请稍后再试~${err}`)
        this.loading = false
      })
    },
    onClear(e) {
      e.preventDefault()
      this.code = ''
      this.result = null
    },
  }
}